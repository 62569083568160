<template>
  <v-main class="batch">
    <div class="app-container pt-10 pb-3">
      <Breadcrumbs
        v-if="breadCrumbItems"
        :items="breadCrumbItems"
        class="mb-4"
      />

      <v-card
        class="batch__header d-flex flex-row justify-space-between align-center mb-6 pa-4"
        color="#EBFAFB"
      >
        <div class="batch__text d-flex">
          <div class="mr-2">
            <div class="d-flex gap-2">
              <h1 class="text-h4 font-weight-bold mr-2">
                {{ batchName }}
              </h1>
              <v-chip v-if="batchStatus" outlined small class="mt-2">
                {{ batchStatus }}
              </v-chip>
            </div>

            <p class="text-subtitle-1 text-medium-emphasis">
              {{ auditTemplateName }}
            </p>
            <p
              v-if="clinicalService"
              class="text-subtitle-1 text-medium-emphasis"
            >
              Service: <b>{{ clinicalService }}</b>
            </p>
            <template v-if="batch && batch.status === BatchStatuses.closed">
              <p class="text-subtitle-1 text-medium-emphasis">
                Audit passed: <b>{{ auditPassedDate }}</b>
              </p>

              <p class="text-subtitle-1 text-medium-emphasis">
                Audit created by: <b>{{ batchCreatedBy }}</b>
              </p>
            </template>
          </div>
          <div class="ml-2">
            <p class="text-subtitle-1 text-medium-emphasis mt-2">
              {{ batchEndDate }}
            </p>
          </div>
        </div>

        <template v-if="!isStatisticsPage">
          <v-btn
            v-if="
              !isNewBatch &&
              batchStatus === BatchStatuses.opened &&
              !isSaveGeneralBatchEnabled &&
              !batchUnderReview
            "
            :disabled="isBatchReviewBtnDisabled"
            color="primary"
            rounded
            normal
            variant="elevated"
            class="ml-2"
            @click="onStartBatchReview"
          >
            {{ isGeneralType ? "Start audit" : "Review" }}
          </v-btn>
          <v-btn
            v-if="isSaveGeneralBatchEnabled"
            color="primary"
            rounded
            normal
            variant="elevated"
            class="ml-2"
            @click="handleGeneralAuditSave"
          >
            {{ isGeneralType ? "Finish Audit" : "Save" }}
          </v-btn>
          <div
            v-else-if="isNewBatch || isBatchGenerated"
            class="d-flex justify-start align-center"
          >
            <v-btn
              :disabled="createBatchLoading"
              color="primary"
              text
              normal
              variant="elevated"
              class="ml-2"
              @click="onNewBatchCancelClick"
            >
              Cancel
            </v-btn>

            <v-btn
              :disabled="!canSaveBatch"
              :loading="createBatchLoading"
              color="primary"
              rounded
              normal
              variant="elevated"
              class="ml-2"
              @click="saveBatch"
            >
              {{ "Save & Create" }}
            </v-btn>
          </div>
        </template>
        <div v-else>
          <v-btn
            color="primary"
            normal
            rounded
            variant="elevated"
            class="ml-2"
            @click="onExportReport"
          >
            <v-icon left dark>mdi-download</v-icon>
            Export PDF
          </v-btn>
        </div>
      </v-card>

      <section v-if="isStatisticsPage && batchReport" class="batch-report mb-4">
        <section class="batch-report__audit-result mb-5">
          <p class="text-h6 mb-5">Audit Result</p>

          <div v-if="isStatisticsPage && isGeneralType">
            <div class="d-flex justify-space-between align-center mb-3">
              <div class="mb-5 d-flex">
                <StatisticItem
                  :value="generalBatchStats?.caseGrade?.score"
                  is-percent
                  text="Audit Score"
                  class="mr-4"
                />
                <StatisticItem
                  :value="generalBatchStats?.caseGrade?.status"
                  text="Audit Status"
                />
              </div>
            </div>
          </div>

          <div v-if="!isGeneralType" class="mb-5 d-flex">
            <StatisticItem
              :value="totalCases"
              text="Total Number Of Cases"
              class="mr-4"
            />
            <StatisticItem
              v-if="isDocReview && batchAuditsGrade"
              :value="batchAuditsGrade.score || 0"
              is-percent
              text="Total Audit Score"
            />
          </div>
          <DocBatchAuditResult
            v-if="isDocReview && evaluationResult && !isGeneralType"
            :evaluation-result="evaluationResult"
          />
          <BatchAuditResult
            v-else-if="
              !isDocReview &&
              evaluationResult &&
              auditTemplateSections &&
              !isGeneralType
            "
            :evaluation-result="evaluationResult"
            :sections="auditTemplateSections"
          />
        </section>
        <v-card v-if="isDocReview" class="batch-report__diagram">
          <BatchReportDiagram
            v-if="batchAuditsGrade"
            :statistic-data="batchAuditsGrade.statistic"
            :batch-type="batchType"
          />
        </v-card>
        <v-card
          v-if="isBatchAuthor || batchReport.description || batchReport.file"
          class="batch-report__info px-6 pt-7 pb-6"
        >
          <div class="batch-report__info-header mb-4">
            <h1 class="batch-report__info-title text-h6 text-left">
              Audit Report
            </h1>
            <v-btn
              v-if="
                isBatchAuthor && (batchReport.description || batchReport.file)
              "
              color="black"
              rounded
              outlined
              small
              variant="elevated"
              class="mb-3"
              @click="editBatchReport"
            >
              <v-icon class="mr-2"> mdi-pencil </v-icon>
              <span>EDIT</span>
            </v-btn>
          </div>
          <template v-if="batchReport.description || batchReport.file">
            <div
              v-if="batchReport.description"
              ref="reportDescription"
              class="batch-report__info-description text-left text-body-1"
              :class="{ 'show-more__active': showMoreActive }"
              @onResize="editBatchReport"
            >
              {{ batchReport.description }}
            </div>
            <span
              v-if="displayMoreButton"
              class="read-more-button"
              @click="showMoreToggle"
            >
              {{ showMoreActive ? "Show less" : "Read more" }}
            </span>
            <v-btn
              v-if="batchReport?.file"
              class="mt-5 w-100"
              color="primary"
              outlined
              :href="batchReport?.file?.url"
              download
            >
              <v-icon left> mdi-file-document </v-icon>
              Download report
              <v-icon right> mdi-download </v-icon>
            </v-btn>
          </template>
          <AddBatchReport
            v-if="
              isBatchAuthor && !(batchReport.description || batchReport.file)
            "
            class="pb-5"
            @add-report="editBatchReport"
          />
        </v-card>
      </section>

      <GeneralAnswerForm v-if="isGeneralType" :batch="batch" />

      <GenerateBatchBlock
        v-if="isGenerateBatchBlockVisible"
        :filter-type="batchType"
        :filter-options="filtersOptions"
        :service="serviceInit"
        :service-name="clinicalService"
        :loading="generateBatchLoading"
        :disabled-button="createBatchLoading"
        :initial-filters="initialTemplateFilters"
        class="mb-4"
        @generate="generateBatch"
      />

      <section v-if="batch && batch.type !== BatchTypes.general">
        <div class="d-flex justify-space-between align-center mb-3">
          <v-badge
            color="grey"
            offset-y="20"
            offset-x="-5"
            :content="totalCases || '0'"
          >
            <h2 class="text-h6 font-weight-bold">Cases</h2>
          </v-badge>

          <v-btn
            v-if="isGenerateBatchBlockVisible"
            color="warning"
            :disabled="!selectedCases.length"
            @click="() => (isUnassignModalOpened = true)"
          >
            Unassign cases
          </v-btn>
        </div>

        <v-skeleton-loader
          v-if="firstLoading"
          :loading="loading"
          type="table"
        />

        <v-data-table
          v-show="!firstLoading"
          v-model="selectedCases"
          :headers="tableHeaders"
          :items="cases"
          :loading="loading"
          :options.sync="options"
          :server-items-length="totalCases"
          :item-class="itemRowBackground"
          :hide-default-footer="$vuetify.breakpoint.mobile"
          :footer-props="tableFooterOptions"
          :mobile-breakpoint="0"
          multi-sort
          v-bind="isGenerateBatchBlockVisible ? { showSelect: true } : {}"
          item-key="_id"
          @update:options="handleTableUpdate"
        >
          <template
            v-if="batch.type === BatchTypes.specialAudit"
            v-slot:item="{ item }"
          >
            <td v-if="isGenerateBatchBlockVisible">
              <v-checkbox
                v-model="selectedCases"
                :value="item"
                hide-details
                absolute
                class="checkbox mt-0 pt-0"
              />
            </td>
            <tr
              :class="{ 'additional-audit-case': isAuditAdditional(item) }"
              @click="onTableRowClick(item)"
            >
              <td v-if="isGenerateBatchBlockVisible"></td>
              <td style="min-width: 140px">
                {{ item.caseReference }}
              </td>
              <td style="min-width: 130px">
                <div class="d-flex flex-column flex-start align-start">
                  <span v-if="item?.consultation?.clinicianId">
                    {{ item.consultation.clinicianId?.name }}
                  </span>
                  <span v-if="item?.advice?.clinicianId">
                    {{ item.advice.clinicianId?.name }}
                  </span>
                </div>
              </td>
              <td style="max-width: 120px">
                <span class="d-block text-truncate">
                  {{ get(item, "prescriptions", "-") }}
                </span>
              </td>
              <td style="min-width: 125px">
                {{
                  get(item, "clinicalCodes", [])
                    .map(({ code }) => code)
                    .join(", ")
                }}
              </td>
              <td style="min-width: 80px">
                {{ item.age }}
              </td>
              <td style="min-width: 100px">
                {{ item.sex }}
              </td>
              <td>{{ item.locationName || "-" }}</td>
              <td style="min-width: 140px">
                {{ getPreviousAudit(item) }}
              </td>
              <td style="min-width: 135px">
                <span>{{ dateToLocalString(item.caseDate, false) }}</span>
              </td>
              <td style="min-width: 135px">
                <span>{{ dateToLocalString(item.createdAt, false) }}</span>
              </td>
            </tr>
          </template>
          <template v-else v-slot:item="{ item }">
            <td v-if="isGenerateBatchBlockVisible">
              <v-checkbox
                v-model="selectedCases"
                :value="item"
                hide-details
                absolute
                class="checkbox mt-0 pt-0"
              />
            </td>
            <tr
              :class="
                getScoreRowColor(get(item, 'audit._id.caseGrade.score', null))
              "
              @click="onTableRowClick(item)"
            >
              <td v-if="isGenerateBatchBlockVisible"></td>
              <td>{{ get(item, "case.caseReference", "-") }}</td>
              <td>{{ get(item, "clinicianName", "-") }}</td>
              <td style="max-width: 220px">
                <CaseStatusChip :status="get(item, 'audit.status', null)" />
              </td>
              <td>
                <span>{{ getAuditScore(item) }}</span>
              </td>
              <td>{{ get(item, "case.locationName", "-") }}</td>
              <td>
                <span>
                  {{ dateToLocalString(get(item, "case.caseDate"), false) }}
                </span>
              </td>
              <td style="min-width: 135px">
                <span>
                  {{ dateToLocalString(get(item, "case.createdAt"), false) }}
                </span>
              </td>
            </tr>
          </template>

          <template #progress>
            <v-progress-linear color="primary" indeterminate />
          </template>
        </v-data-table>
      </section>
    </div>

    <DeleteModal
      title="Unassign cases"
      description="Are you sure you want to unassign selected cases from generated batch?"
      :is-opened="isUnassignModalOpened"
      :loading="loading"
      submit-text="Unassign"
      @submit="removeCaseFromGenerated"
      @close="isUnassignModalOpened = false"
    />

    <TextModal
      v-if="batchReport"
      :dialog="showReportEditModal"
      title="Batch Report"
      :initial-value="batchReport.description"
      text-placeholder="Enter a report description"
      :action-buttons="reportModalButtons"
      :read-only="!isBatchAuthor"
      :disable-without-changes="false"
      :toggle-after-submit="false"
      :auto-grow="false"
      :height="266"
      :loading="descriptionLoading"
      @cancel="closeReportModalToggle"
      @send="onBatchReportSave"
    >
      <template #append>
        <v-file-input
          ref="fileInput"
          :error-messages="uploadErrorMessage"
          :disabled="!isBatchAuthor"
          :value="reportFileView"
          :loading="fileLoading"
          :loader-height="4"
          class="mt-2"
          hide-details
          dense
          outlined
          show-size
          prepend-icon="mdi-file-document"
          @change="setNewFile"
        />
      </template>
    </TextModal>
  </v-main>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useBatchReviewStore } from "@/stores/batchReview";
import {
  getBatchById,
  generateBatch,
  createBatch,
  getGroupReviewSession,
  getBatchCases,
  getBatchResult,
  uploadReport,
  updateBatch,
  batchUnassignCases,
} from "@/services/batch";
import { getSelectOptions } from "@/services/cases";
import { useUserStore } from "@/stores/user";
import { getAuditTemplate } from "@/services/auditTemplates";
import { get } from "@/helpers/get";
import { parseNavigationQuery } from "@/helpers/parseNavQuery";
import {
  getPreviousAudit,
  isAuditAdditional,
  getScoreRowColor,
  getAuditScore,
} from "@/helpers/audit";
import { updateAudit } from "@/services/audit";
import GenerateBatchBlock from "@/components/batch/GenerateBatchBlock.vue";
import BatchReportDiagram from "@/components/batch/BatchReportDiagram.vue";
import TextModal from "@/components/common/TextModal.vue";
import AddBatchReport from "@/components/batch/AddBatchReport.vue";
import StatisticItem from "@/components/common/StatisticItem.vue";
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import BatchAuditResult from "@/components/batch/BatchAuditResult.vue";
import DocBatchAuditResult from "@/components/batch/DocBatchAuditResult.vue";
import dateToLocalString from "@/helpers/dateToLocalString";
import CaseStatusChip from "@/components/common/CaseStatusChip.vue";
import { waitForElement } from "@/helpers/waitForElement";
import DeleteModal from "@/components/common/DeleteModal.vue";
import {
  BatchStatuses,
  BatchTypes,
  CasesStatuses,
  TableRowsPerPage,
  CasesStatusesIcons,
  CaseAuditTypes,
  PagesBreadCrumbTitles,
  RoutesMapping,
} from "@/misc/constants";
import {
  FILE_SIZE_LIMIT,
  REPORT_DESCRIPTION_DEFAULT_HEIGHT,
  ReportModalButtons,
  SpecialAuditTableHeaders,
  ClinicianAuditTableHeaders,
} from "./constants";
import GeneralAnswerForm from "@/components/batch/GeneralAnswerForm.vue";
import { getClinicalServiceById } from "@/services/clinicalServices";
import axios from "axios";

export default {
  name: "BatchPage",

  components: {
    AddBatchReport,
    GenerateBatchBlock,
    BatchReportDiagram,
    TextModal,
    Breadcrumbs,
    BatchAuditResult,
    StatisticItem,
    CaseStatusChip,
    DocBatchAuditResult,
    GeneralAnswerForm,
    DeleteModal,
  },

  data() {
    const tableFooterOptions = {
      "items-per-page-options": TableRowsPerPage,
    };

    return {
      batch: null,
      isNewBatch: false,
      filtersOptions: null,
      tableFooterOptions,
      loading: false,
      generateBatchLoading: false,
      createBatchLoading: false,
      fileLoading: false,
      descriptionLoading: false,
      cases: [],
      selectedCases: [],
      isUnassignModalOpened: false,
      totalCases: null,
      options: {},
      BatchTypes,
      BatchStatuses,
      CasesStatusesIcons,
      isStatisticsPage: false,
      groupReviewSession: null,
      firstLoading: true,
      batchResultsLoading: false,
      showReportEditModal: false,
      uploadErrorMessage: "",
      showMoreActive: false,
      newFile: undefined,
      reportFileView: null,
      displayMoreButton: false,
      auditTemplate: null,
      initialTemplateFilters: null,
      parsedBreadCrumbs: null,
      clinicalService: null,
      accountLogoEncoded: null,
    };
  },

  computed: {
    ...mapState(useUserStore, ["user", "currentAccount"]),
    ...mapState(useBatchReviewStore, [
      "currentCaseUnderReview",
      "attendees",
      "batchReport",
      "batchUnderReview",
      "generalBatchProceeded",
      "generalBatchStats",
    ]),
    breadCrumbItems() {
      return [
        ...(this.parsedBreadCrumbs || [
          {
            title: PagesBreadCrumbTitles.batches,
            to: RoutesMapping[PagesBreadCrumbTitles.batches],
          },
        ]),
        this.isNewBatch || !this.batch
          ? { title: "New Batch", disabled: true }
          : { title: this.batch.name, disabled: true },
      ];
    },
    batchName() {
      if (this.isNewBatch || !this.batch) {
        return this.$route.query?.name || "";
      }
      return this.batch.name;
    },
    batchType() {
      if (this.isNewBatch || !this.batch) {
        return this.$route.query?.type || "";
      }
      return this.batch.type;
    },
    batchEndDate() {
      if (!this.batch?.endDate) return null;
      return dateToLocalString(this.batch.endDate, false);
    },
    serviceInit() {
      return (
        this.$route.query?.service ||
        get(this.batch, "clinicalServiceId._id", this.batch?.clinicalServiceId)
      );
    },
    auditTemplateName() {
      if (!this.auditTemplate) return "";
      return this.auditTemplate.name;
    },
    auditPassedDate() {
      if (!this.batch && !this.batch.status !== BatchStatuses.closed) return "";
      return dateToLocalString(this.batch.endDate);
    },
    batchCreatedBy() {
      if (!this.batch && !this.batch.status !== BatchStatuses.closed) return "";
      return this.batch.createdBy?.name;
    },
    batchStatus() {
      if (!this.batch) return null;
      return this.batch.status;
    },
    isDocReview() {
      return this.batchType === BatchTypes.docReview;
    },
    tableHeaders() {
      let headers;
      switch (this.batchType) {
        case BatchTypes.docReview:
          headers = ClinicianAuditTableHeaders;
          break;
        case BatchTypes.specialAudit:
          headers = SpecialAuditTableHeaders;
          break;
        default:
          headers = [];
      }
      return headers;
    },
    isBatchGenerated() {
      if (!this.batch) return false;
      return this.batch.status === BatchStatuses.generated;
    },
    canSaveBatch() {
      if (!this.batch && !this.isBatchGenerated) return false;
      if (
        this.batchType === BatchTypes.groupReview &&
        this.attendees.length < 2
      )
        return false;
      return this.batch.clinicianBasedCases
        ? !!this.batch.clinicianBasedCases.length
        : false;
    },
    isGenerateBatchBlockVisible() {
      return (
        (this.isNewBatch || this.isBatchGenerated) &&
        this.filtersOptions &&
        this.initialTemplateFilters
      );
    },
    isBatchReviewBtnDisabled() {
      if (!this.batch) return true;
      return (
        !this.batch ||
        (this.batchType === BatchTypes.groupReview && this.attendees.length < 2)
      );
    },
    reportModalButtons() {
      return this.batchReport?.description || this.batchReport?.file
        ? ReportModalButtons
        : undefined;
    },
    isBatchAuthor() {
      return this.batch?.createdBy?._id === this.user?._id;
    },
    batchReport() {
      return this.batch?.report || null;
    },
    evaluationResult() {
      if (!this.batchReport) return null;
      return this.batchReport?.evaluationResult || null;
    },
    auditTemplateSections() {
      if (!this.auditTemplate) return null;
      return this.auditTemplate?.evaluation?.sections || [];
    },
    batchAuditsGrade() {
      if (!this.batchReport || !this.batchReport?.auditsGrade) return null;
      return this.batchReport.auditsGrade;
    },
    isSaveGeneralBatchEnabled() {
      return (
        this.batchUnderReview &&
        this.batchType === BatchTypes.general &&
        this.generalBatchProceeded
      );
    },
    isGeneralType() {
      return this.batch && this.batchType === BatchTypes.general;
    },
    accountLogoUrl() {
      return `${process.env.VUE_APP_MEDIA_CDN_URL}/${this.currentAccount?.logoUrl}`;
    },
  },

  watch: {
    batchReport: {
      handler() {
        this.$nextTick(() => {
          this.displayMoreButton =
            this.$refs?.reportDescription?.scrollHeight >
            REPORT_DESCRIPTION_DEFAULT_HEIGHT;
        });
      },
      deep: true,
    },
  },

  async mounted() {
    waitForElement(".batch-report__info-description", (element) => {
      this.displayMoreButton =
        element.scrollHeight > REPORT_DESCRIPTION_DEFAULT_HEIGHT;
    });
    this.isNewBatch = this.$route.params?.id === "new";
    if (this.isNewBatch && this.batchType === BatchTypes.general) {
      return this.generateAndSaveGeneralBatch();
    }
    if (
      !this.isNewBatch ||
      this.$route.query?.type === BatchTypes.groupReview
    ) {
      await this.initialize();
    }
    if (
      (this.isNewBatch || this.batch?.status === BatchStatuses.generated) &&
      this.$route.query?.type !== BatchTypes.groupReview
    ) {
      await Promise.all([this.fetchAuditTemplate(), this.fetchFilterOptions()]);
      this.initialTemplateFilters = this.auditTemplate?.conditions || {};
    }
    this.parsedBreadCrumbs = parseNavigationQuery(this.$route.query);

    this.donwloadLogoImage();
  },

  beforeRouteLeave(to, from, next) {
    if (to.name !== "case" || (to.name === "case" && this.isStatisticsPage)) {
      this.reset();
    }
    next();
  },

  beforeRouteUpdate(to, from, next) {
    const { params } = to;
    if (params?.id === "new" && this.batch) {
      next(`/batches/${this.batch._id}`);
    }
    next();
  },

  methods: {
    isAuditAdditional,
    getPreviousAudit,
    get,
    getScoreRowColor,
    getAuditScore,
    ...mapActions(useBatchReviewStore, [
      "startBatchReview",
      "addAttendees",
      "reset",
      "storeBatchData",
      "updateAudit",
      "endBatchReview",
    ]),
    dateToLocalString,
    async initialize() {
      await this.fetchBatch();
      await Promise.all([this.fetchCases(), this.fetchAuditTemplate()]);
    },
    async removeCaseFromGenerated() {
      this.isUnassignModalOpened = false;
      const selectedCasesId = this.selectedCases.map((cs) =>
        this.batch.type === BatchTypes.specialAudit ? cs._id : cs?.case?._id
      );
      await batchUnassignCases(this.batch._id, selectedCasesId);
      this.options.page = 1;
      await this.handleTableUpdate();
    },
    async fetchBatch() {
      try {
        let { id } = this.$route.params;
        if (
          this.isNewBatch &&
          this.$route.query?.type === BatchTypes.groupReview
        ) {
          id = this.$route.query?.batchId;
        }
        this.batch = await getBatchById(id);

        if (this.batch.type === BatchTypes.groupReview) {
          this.groupReviewSession = await getGroupReviewSession(this.batch._id);
          this.startBatchReview(this.batch);
          this.addAttendees(this.groupReviewSession.attendees);
        }
        this.isStatisticsPage = this.$route.name === "batchStatistic";
        if (this.batch?.report && this.isStatisticsPage) {
          this.updateReportFileView();
        }
        if (this.batch.clinicalServiceId) {
          const { name } = await getClinicalServiceById(
            this.batch.clinicalServiceId
          );
          this.clinicalService = name;
        }
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Fetch Batch Data",
          text: e?.response?.data?.message || e?.message || JSON.stringify(e),
        });
      }
    },
    async handleTableUpdate() {
      await this.fetchCases();
    },
    async fetchCases() {
      try {
        this.loading = true;
        const query = this.buildQuery();
        const { data, total } = await getBatchCases(this.batch._id, query);
        this.totalCases = total;
        this.cases = data;
      } catch (e) {
        this.$notify({
          type: "error",
          text: e?.response?.data?.message || e?.message || JSON.stringify(e),
        });
      } finally {
        this.loading = false;
        this.firstLoading = false;
        this.selectedCases = [];
      }
    },
    buildQuery() {
      const queryParams = {};
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      queryParams.page = page || 1;
      queryParams.pageSize = itemsPerPage || 10;
      if (this.batchType === BatchTypes.specialAudit) {
        queryParams.populate = [
          { path: "clinicalCodes" },
          { path: "specialAudits.auditId", select: "executionOrder createdAt" },
          { path: "specialAudits.batchId", select: "name" },
        ];
      }

      if (sortBy && sortDesc && sortBy.length && sortDesc.length) {
        const sort = sortBy.reduce((acc, el, ind) => {
          const sortBy = `${el}:${sortDesc[ind] ? -1 : 1}`;
          acc += ind === 0 ? sortBy : `,${sortBy}`;
          return acc;
        }, "");
        queryParams.sort = sort;
      }

      return queryParams;
    },
    onNewBatchCancelClick() {
      this.$router.push("/batches");
    },
    itemRowBackground(item) {
      return item.status === CasesStatuses.groupReview
        ? "group-review-needed-status"
        : "";
    },
    async generateAndSaveGeneralBatch() {
      try {
        const body = {
          type: this.batchType,
          name: this.batchName,
          service: this.serviceInit,
          auditorId: this.user._id,
          auditTemplateId: this.$route.query?.templateId,
        };
        this.batch = await generateBatch(body);
        this.saveBatch();
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Generate General Audit",
          text: e?.response?.data?.message || e?.message || JSON.stringify(e),
        });
      }
    },
    async generateBatch(queryData) {
      this.generateBatchLoading = true;
      try {
        const { filters, casesAmount } = queryData;
        const body = {
          type: this.batchType,
          name: this.batchName,
          service: this.serviceInit,
          auditorId: this.user._id,
          ...(this.auditTemplate
            ? { auditTemplateId: this.auditTemplate._id }
            : {}),
          ...(filters ? { filters } : {}),
          ...(casesAmount ? { casesAmount } : {}),
        };
        this.batch = await generateBatch(body);
        if (this.batch.type === BatchTypes.groupReview) {
          this.groupReviewSession = await getGroupReviewSession(this.batch._id);
          this.startBatchReview(this.batch);
          this.addAttendees(this.groupReviewSession.attendees);
        }
        this.options.page = 1;
        this.$notify({
          type: "success",
          title: "Generate audit",
          text: "Audit is successfully generated",
        });
        if (this.isNewBatch) {
          this.$router.push({ path: `/batches/${this.batch._id}` });
        }
        await this.fetchCases();
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Generate audit",
          text: e?.response?.data?.message || e?.message || JSON.stringify(e),
        });
      } finally {
        this.generateBatchLoading = false;
      }
    },
    async saveBatch() {
      this.createBatchLoading = true;
      try {
        if (!this.batch) return;
        const { _id } = await createBatch({
          id: this.batch._id,
          type: this.batch.type,
          service: this.serviceInit,
          name: this.batch.name,
        });
        const { prev } = this.$route.query;
        if (this.$route.params.id === "new") {
          this.$router
            .push({
              path: `/batches/${_id}`,
              query: { prev },
            })
            .catch(function () {});
        }
        this.$notify({
          type: "success",
          title: "Create audit",
          text: "Audit is successfully created",
        });
        await this.initialize();
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Save batch",
          text: e?.response?.data?.message || e?.message || JSON.stringify(e),
        });
      } finally {
        this.createBatchLoading = false;
      }
    },
    async onStartBatchReview() {
      try {
        if (!this.batch) return;
        await this.startBatchReview(this.batch);

        if (this.batch.type === BatchTypes.general) return;
        const { _id, caseType } = this.currentCaseUnderReview;
        this.$router.push({
          path: `/cases/${_id}`,
          query: {
            caseType,
            underReview: true,
            ...this.getNavigationHistoryQuery(),
          },
        });
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Starting batch review",
          text: e?.response?.data?.message || e?.message || JSON.stringify(e),
        });
      }
    },
    onTableRowClick(item) {
      const query = { ...this.getNavigationHistoryQuery() };
      let caseId;
      if (this.batch.type === BatchTypes.specialAudit) {
        const audit = item?.specialAudits.find(
          (audit) => audit?.batchId._id === this.batch._id
        );
        query.auditId = audit?.auditId?._id || null;
        query.caseType = CaseAuditTypes.all;
        caseId = item._id;
      } else {
        query.auditId = get(item, "audit._id._id", null);
        query.caseType = get(item, "type", null);
        caseId = get(item, "case._id", null);
      }
      if (this.isDocReview) {
        this.reset();
      }
      this.$router.push({ path: `/cases/${caseId}`, query });
    },
    async generateBatchResult() {
      try {
        this.batchResultsLoading = true;
        if (!this.batch) return;
        const response = await getBatchResult(this.batch._id);
        const url = URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${this.batch.name}(${this.batch.type})-${new Date(this.batch.endDate)
            .toISOString()
            .slice(0, 10)}.csv`
        );
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
        link.remove();
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Generate batch results",
          text: e?.response?.data?.message || e?.message || JSON.stringify(e),
        });
      } finally {
        this.batchResultsLoading = false;
      }
    },
    async uploadFile(value) {
      if (value === undefined) return;
      try {
        if (!value && this.batchReport?.file) {
          this.fileLoading = true;
          this.batch = await updateBatch(this.batch._id, {
            report: { file: null },
          });
        }
        if (value?.size > FILE_SIZE_LIMIT) {
          this.uploadErrorMessage =
            "Report size should be less than 10 MB • Failed";
          this.newFile = undefined;
          return;
        }
        this.uploadErrorMessage = "";

        if (value) {
          this.fileLoading = true;
          this.batch = await uploadReport(this.batch._id, value);
        }
      } catch (e) {
        this.$notify({
          type: "error",
          title: "File uploading error",
          text: e?.response?.data?.message || e?.message || JSON.stringify(e),
        });
      } finally {
        this.fileLoading = false;
        this.newFile = undefined;
        this.updateReportFileView();
      }
    },
    setNewFile(value) {
      this.newFile = value;
    },
    async closeReportModalToggle(value) {
      this.newFile = undefined;
      this.updateReportFileView();
      this.showReportEditModal = value;
    },
    editBatchReport() {
      this.showReportEditModal = true;
    },
    async onBatchReportSave(description) {
      const isDescriptionUpdateNeeded =
        this.batchReport.description !== description;
      await Promise.all([
        isDescriptionUpdateNeeded
          ? this.updateBatch({ report: { description } })
          : null,
        this.uploadFile(this.newFile),
      ]);
      this.showReportEditModal = false;
    },
    async updateBatch(payload) {
      try {
        this.descriptionLoading = true;
        this.batch = await updateBatch(this.batch._id, payload);
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Update batch",
          text: e?.response?.data?.message || e?.message || JSON.stringify(e),
        });
      } finally {
        this.descriptionLoading = false;
      }
    },
    showMoreToggle() {
      this.showMoreActive = !this.showMoreActive;
    },
    updateReportFileView() {
      if (this.batchReport?.file) {
        this.reportFileView = new File([""], this.batchReport?.file?.key, {
          type: "application/pdf",
        });
      } else {
        this.reportFileView = [];
      }
    },
    async fetchFilterOptions() {
      try {
        const queryString =
          this.$route.query?.type === BatchTypes.specialAudit ||
          this.batch?.type === BatchTypes.specialAudit
            ? "locationName,caseType,sex"
            : "locationName,caseType";
        this.filtersOptions = await getSelectOptions(
          queryString,
          this.serviceInit
        );
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Fetching filters options error",
          text: e?.response?.data?.message || e?.message || JSON.stringify(e),
        });
      }
    },
    async fetchAuditTemplate() {
      try {
        const templateId =
          this.$route.query?.templateId || this.batch?.auditTemplateId;
        if (!templateId) return;
        this.auditTemplate = await getAuditTemplate(templateId);
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Fetching audit template error",
          text: e?.response?.data?.message || e?.message || JSON.stringify(e),
        });
      }
    },
    async onExportReport() {
      const { AuditReportFactory } = await import("@/libs/AuditReportFactory");
      const reportFactory = new AuditReportFactory();

      const options = {
        batchName: this.batchName,
        batchDate: this.batch?.endDate,
        templateName: this.auditTemplateName,
        totalCases: this.totalCases,
        tableSelector: "#audit-result-table table",
        report: this.batch?.report?.description,
        caseGrade: this.generalBatchStats?.caseGrade,
        caseEvaluation: this.generalBatchStats?.caseEvaluation,
        batchType: this.batch?.type,
        createdBy: this.batch?.createdBy?.name,
        serviceName: this.clinicalService,
        sections: this.auditTemplate?.evaluation?.sections,
        accountLogo: this.accountLogoEncoded,
      };

      try {
        if (this.isDocReview) {
          const totalScore = `${this.batchAuditsGrade.score.toFixed(1)}%`;
          reportFactory.buildDoctorAuditReport({ ...options, totalScore });
        } else {
          reportFactory.buildSpecialAuditReport(options);
        }
        reportFactory.downloadPdf(`${this.batchName}-Report`);
      } catch (error) {
        this.$notify({
          type: "error",
          title: "Report Export Fail",
          text: error.message,
        });
      }
    },
    getNavigationHistoryQuery() {
      const { prev } = this.$route.query;
      const initialBreadcrumbTitle = prev || PagesBreadCrumbTitles.batches;
      return {
        prev: initialBreadcrumbTitle,
        title: PagesBreadCrumbTitles.batches,
        path: `${this.batchName}:${this.batch._id}/${
          this.isStatisticsPage ? "statistic" : ""
        }`,
      };
    },
    async handleGeneralAuditSave() {
      try {
        const data = {
          caseEvaluation: this.generalBatchStats?.caseEvaluation,
          isComplete: true,
          caseGrade: this.generalBatchStats?.caseGrade,
        };
        await updateAudit(this.batch.auditIds[0]._id, data);
        await this.endBatchReview();
        this.$notify({
          type: "success",
          title: "Audit update",
          text: "Successfully updated",
        });
        this.$router.push({ path: `/batches/${this.batch._id}/statistic` });
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Audit update",
          text: e?.response?.data?.message || e.message || JSON.stringify(e),
        });
      }
    },
    async donwloadLogoImage() {
      const imgResponse = await axios.get(this.accountLogoUrl, {
        responseType: "arraybuffer",
        validateStatus: (status) => status < 400 || status === 403,
      });
      if (imgResponse.status < 400)
        this.accountLogoEncoded = `data:image/png;base64,${Buffer.from(
          imgResponse.data,
          "binary"
        ).toString("base64")}`;
    },
  },
};
</script>

<style lang="scss">
.batch {
  .v-data-table__wrapper {
    overflow: unset;

    .checkbox {
      position: absolute;
      top: 12px;
      left: 17px;
      z-index: 1;
    }

    @media (max-width: 1600px) {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  &__delete-btn {
    &--disabled {
      cursor: not-allowed !important;
      opacity: 0.2;
    }
  }

  .additional-audit-case {
    background-color: #fff2f1;
  }

  .read-more-button {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #01c6d0;
    cursor: pointer;
  }

  &-report {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    &__audit-result {
      width: 100%;
    }

    &__diagram {
      width: 33%;
    }

    &__info {
      width: 65%;
    }

    &__add {
      height: 90%;
      justify-content: center;
      align-items: center;
      gap: 16px;
      flex-direction: column;
      display: flex;
    }

    &__add-icon {
      display: flex;
      justify-content: center;
      background: rgba(0, 0, 0, 0.06);
      width: 72px;
      height: 72px;
      border-radius: 50%;
    }

    &__add-text {
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
    }

    &__info-header {
      display: flex;
      justify-content: space-between;
    }

    &__info-description {
      max-height: 292px;
      overflow: hidden;
    }
  }

  &__details {
    &--info {
      width: 65%;
    }

    &--sidebar {
      width: 33%;
    }

    @media (max-width: 992px) {
      &--info,
      &--sidebar {
        width: 100%;
      }
    }
  }
}
.show-more__active {
  max-height: max-content;
}
</style>
