<template>
  <section class="doc-audit-result">
    <v-data-table
      id="audit-result-table"
      :headers="tableHeaders"
      :items="evaluationResult"
      disable-pagination
      disable-sort
      hide-default-footer
    >
      <template v-slot:item="{ item }">
        <tr>
          <td style="font-weight-bold">{{ item.name }}.</td>
          <td>{{ item.percent }}%</td>
          <td>{{ item.amount }}</td>
        </tr>
      </template>
    </v-data-table>
  </section>
</template>

<script>
export default {
  name: "DocBatchAuditResult",

  props: {
    evaluationResult: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    const tableHeaders = [
      {
        text: "Results",
        sortable: false,
        value: "name",
      },
      {
        text: "Value, %",
        sortable: false,
        value: "percent",
      },
      {
        text: "Value, n",
        sortable: false,
        value: "amount",
      },
    ];

    return {
      tableHeaders,
    };
  },
};
</script>
